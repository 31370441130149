<template>
  <card
    class="
      fixed
      right-2
      bottom-2
      md:right-auto md:bottom-auto md:top-1/2 md:mt-negative
      leading-0
      z-20
      items-center
      justify-between
      md:flex-col
      flex
      md:p-none
    "
    :class="{ 'left-2': !shifted, 'left-100': shifted }"
  >
    <tool-bar-icon
      :icon="createOutline"
      class="order-1 md:hidden"
      @click="
        $router.push(`/project/${$route?.params?.projectId}/personal-note`)
      "
    />
    <tool-bar-icon
      :icon="pricetagsOutline"
      :active="isAnnotation"
      class="order-2 md:order-2"
      @click="startCreateAnnotation()"
      :title="$t('annotations.label')"
    />
    <tool-bar-icon
      :icon="constructOutline"
      :active="isMeasureToolsMenuOpen"
      class="order-3 md:order-3"
      @click="openMeasureTools()"
      :title="$t('measureTools.toolBar')"
    />
    <tool-bar-icon
      :icon="optionsOutline"
      :active="isOptionsMenuOpen"
      class="order-4 md:order-1"
      @click="openOptions()"
      :title="$t('global.options')"
    />
  </card>
</template>

<script>
import { defineComponent } from 'vue'
import { mapMutations, mapGetters } from 'vuex'

import {
  createOutline,
  pricetagsOutline,
  constructOutline,
  optionsOutline,
} from 'ionicons/icons'

import Card from '@/components/atoms/Card'
import ToolBarIcon from '@/components/molecules/ToolBarIcon'
import { ViewerStatus, isAnnotation } from '@/utils/viewer'

export default defineComponent({
  components: {
    Card,
    ToolBarIcon,
  },
  props: {
    shifted: {
      type: Boolean,
      default: () => false,
    },
    project: {
      type: Object,
    },
    isOptionsMenuOpen: {
      type: Boolean,
      default: false,
    },
    isMeasureToolsMenuOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'options-menu-open',
    'create-annotation',
    'cancel-create-annotation',
    'measures-tool-menu-open',
  ],
  setup() {
    return {
      createOutline,
      pricetagsOutline,
      constructOutline,
      optionsOutline,
    }
  },
  data() {
    return {
      annotationCreateStep: 0,
    }
  },
  computed: {
    isAnnotation() {
      return isAnnotation(this.viewerStatus)
    },
    ...mapGetters(['viewerStatus']),
  },
  methods: {
    closeAllMenus() {
      this.$emit('measures-tool-menu-open', false)
      this.$emit('options-menu-open', false)
      // this.setViewerStatus(ViewerStatus.DEFAULT)
      this.$emit('cancel-create-annotation')
    },
    startCreateAnnotation() {
      if (this.viewerStatus === ViewerStatus.DEFAULT) {
        this.$emit('measures-tool-menu-open', false)
        this.$emit('options-menu-open', false)
        this.setViewerStatus(ViewerStatus.ANNOTATION_PLACEMENT)
        this.$emit('create-annotation')
      } else {
        this.closeAllMenus()
      }
    },
    openMeasureTools() {
      if (this.isMeasureToolsMenuOpen) {
        this.closeAllMenus()
      } else {
        // this.setViewerStatus(ViewerStatus.DEFAULT)
        this.$emit('options-menu-open', false)
        this.$emit('cancel-create-annotation')
        this.$emit('measures-tool-menu-open', true)
      }
    },
    openOptions() {
      if (this.isOptionsMenuOpen) {
        this.closeAllMenus()
      } else {
        this.$emit('measures-tool-menu-open', false)
        // this.setViewerStatus(ViewerStatus.DEFAULT)
        this.$emit('cancel-create-annotation')
        this.$emit('options-menu-open', true)
      }
    },
    ...mapMutations(['setViewerStatus']),
  },
})
</script>
