<template>
  <layer-tool-button
    :class="className"
    :icon="pencilOutline"
    @click="$emit('click')"
    :tooltip="$t('global.edit')"
  />
</template>

<script>
import { defineComponent } from 'vue'
import { pencilOutline } from 'ionicons/icons'
import { mapClassName } from '@/utils/classNames'

import LayerToolButton from '@/components/molecules/LayerToolButton'

export default defineComponent({
  components: {
    LayerToolButton,
  },
  emits: ['click'],
  props: {
    class: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      pencilOutline,
    }
  },
  computed: {
    ...mapClassName(),
  },
})
</script>
