<template>
  <span
    class="
      absolute
      top-0
      right-0
      bottom-0
      w-1.5
      bg-primary
      rounded-l
      hidden
      md:block
    "
  />
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({})
</script>
