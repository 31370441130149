<template>
  <box
    :class="{
      ' md:right-2 md:flex md:flex-col md:items-center md:justify-center': true,
      'md:top-32': !shifted,
      'md:top-115': shifted,
    }"
    :open="Boolean(annotation)"
    @close="close"
  >
    <div class="md:w-72 p-2 relative" v-if="annotation">
      <div
        class="
          flex
          justify-end
          md:justify-start
          pl-4
          pb-1
          leading-0
          relative
          md:pr-10
          absolute
          top-2
          md:top-0
          right-0
          md:right-auto md:left-0
        "
      >
        <annotation-tool-button-edit
          @click="$emit('edit-annotation', annotation)"
          class="pr-4 md:pr-2"
        />
        <annotation-tool-button-delete
          :annotation="annotation"
          class="pr-4 md:pr-2"
          @annotation-deleted="args => $emit('annotation-deleted', args)"
          @change-annotation-visibility="
            args => $emit('change-annotation-visibility', args)
          "
        />
        <layer-tool-button-visibility
          class="pr-4 md:pr-2"
          :isVisible="isAnnotationVisible"
          @click="toggleAnnotationVisibility"
        />
      </div>
      <icon-button
        :icon="closeOutline"
        size="fat"
        primary
        @click="close"
        class="hidden md:block absolute top-0 right-0"
        :title="$t('global.close')"
        tooltipPlacement="left"
      />
      <section-list>
        <section-item class="relative md:py-2">
          <div
            class="
              flex
              items-center
              text-lg
              font-montserrat
              text-primary
              font-semibold
            "
          >
            <div
              class="w-5 h-5 rounded mr-2"
              :style="{ backgroundColor: annotation.color }"
            />
            {{ annotation.title }}
          </div>
        </section-item>
        <section-item class="md:py-2">
          <div
            class="text-base font-montserrat text-secondary-700 font-semibold"
            v-if="annotation.type === AnnotationTypes.TEXT"
          >
            {{ annotation.description }}
          </div>
          <div
            class="text-base font-montserrat text-secondary-700 font-semibold"
            v-if="annotation.type === AnnotationTypes.URL"
          >
            <a
              :href="annotation.url"
              class="text-blue-500 inline-flex align-center w-full truncate"
              target="_blank"
            >
              <icon class="leading-0 pr-1" size="small" :icon="linkOutline" />
              {{ annotation.url }}
            </a>
          </div>
          <div v-if="annotation.type === AnnotationTypes.PHOTO">
            <div class="text-center">
              <button
                class="
                  text-secondary-700
                  inline-flex
                  items-center
                  hover:text-primary
                  font-semibold
                "
                @click="isFullSizePhotoOpen = true"
              >
                <icon-button :icon="expandOutline" class="leading-0 mr-1" />
                {{ $t('annotations.maximize') }}
              </button>
            </div>
            <img
              alt="annotation's photo"
              :src="annotationMediaUrl"
              class="rounded w-full"
            />
          </div>
          <div
            class="text-base font-montserrat text-secondary-700 font-semibold"
            v-if="annotation.type === AnnotationTypes.REALTIME"
          >
            <div class="text-center">
              <button
                class="
                  text-secondary-700
                  inline-flex
                  items-center
                  hover:text-primary
                  font-semibold
                "
                @click="isFullSizePreviewOpen = true"
              >
                <icon-button :icon="expandOutline" class="leading-0 mr-1" />
                {{ $t('annotations.maximize') }}
              </button>
              <div
                class="text-blue-500 w-full truncate cursor-pointer"
                @click="isFullSizePreviewOpen = true"
              >
                {{ annotation.url }}
              </div>
            </div>
          </div>
        </section-item>
      </section-list>
    </div>
  </box>
  <fullscreen-modal
    :open="isFullSizePreviewOpen"
    @close="isFullSizePreviewOpen = false"
  >
    <div class="flex justify-end">
      <icon-button
        :icon="closeOutline"
        size="fat"
        primary
        @click="isFullSizePreviewOpen = false"
        :title="$t('global.close')"
        tooltipPlacement="left"
      />
    </div>

    <iframe
      class="rounded border-2 border-primary rn-iframe"
      :src="annotation.url"
      title="Exemple d'iframe"
    />
  </fullscreen-modal>
  <fullscreen-modal
    :open="isFullSizePhotoOpen"
    @close="isFullSizePhotoOpen = false"
  >
    <div class="flex justify-end">
      <icon-button
        :icon="closeOutline"
        size="fat"
        primary
        @click="isFullSizePhotoOpen = false"
        :title="$t('global.close')"
        tooltipPlacement="left"
      />
    </div>
    <img
      alt="annotation's photo"
      :src="annotationMediaUrl"
      class="rounded w-full"
    />
  </fullscreen-modal>

  <!-- <ion-modal
    :is-open="isFullSizePhotoOpen"
    @didDismiss="isFullSizePhotoOpen = false"
    css-class="rn-modal"
  >
    <ion-content>
      <img
        alt="annotation's photo"
        :src="annotationMediaUrl"
        class="rounded rn-large-image"
    /></ion-content>
  </ion-modal> -->
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import {
  closeOutline,
  linkOutline,
  expandOutline,
  trashOutline,
} from 'ionicons/icons'
// import { IonModal, IonContent } from '@ionic/vue'

import IconButton from '@/components/atoms/IconButton'
import Icon from '@/components/atoms/Icon'
import FullscreenModal from '@/components/molecules/FullscreenModal'
import SectionList from '@/components/atoms/SectionList'
import SectionItem from '@/components/atoms/SectionItem'
import LayerToolButtonVisibility from '@/components/organisms/LayerToolButtonVisibility'
import AnnotationToolButtonDelete from '@/components/organisms/AnnotationToolButtonDelete'
import AnnotationToolButtonEdit from '@/components/organisms/AnnotationToolButtonEdit'

import { AnnotationTypes } from '@/utils/annotations'

import Box from '@/components/molecules/Box'

export default defineComponent({
  components: {
    Box,
    Icon,
    IconButton,
    FullscreenModal,
    SectionList,
    SectionItem,
    LayerToolButtonVisibility,
    AnnotationToolButtonDelete,
    AnnotationToolButtonEdit,
    // IonModal,
    // IonContent,
  },
  emits: [
    'close',
    'edit-annotation',
    'change-annotation-visibility',
    'annotation-deleted',
  ],
  props: {
    shifted: {
      type: Boolean,
      default: () => false,
    },
    project: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return {
      AnnotationTypes,
      closeOutline,
      linkOutline,
      expandOutline,
      trashOutline,
    }
  },
  data() {
    return {
      isFullSizePreviewOpen: false,
      isFullSizePhotoOpen: false,
    }
  },
  computed: {
    annotation() {
      return this.getDisplayedAnnotation(this.project?.id)
    },
    isAnnotationVisible() {
      return this.$store.getters.isAnnotationVisible(
        this.project?.id,
        this.annotation?.id
      )
    },
    annotationMediaUrl() {
      return this.$store.getters.getMediaUrl(this.annotation?.mediaId)
    },
    ...mapGetters(['getDisplayedAnnotation']),
  },
  methods: {
    close() {
      this.closeAnnotation({ projectId: this.project?.id })
    },
    toggleAnnotationVisibility() {
      this.$emit('change-annotation-visibility', {
        annotation: this.annotation,
        visibility: !this.isAnnotationVisible,
      })
    },
    ...mapActions(['closeAnnotation', 'removeAnnotation']),
  },
  watch: {
    annotation() {
      if (!this.annotation) {
        this.close()
      }
    },
  },
})
</script>

<style scoped>
.rn-iframe {
  width: 74vw;
  height: 74vh;
}

.rn-overlay {
  opacity: 0;
}

.rn-photo-container:hover .rn-overlay {
  opacity: 1;
}

.rn-large-image {
  width: 74vw;
}
</style>

<style>
.rn-modal {
  --border-radius: 5px;
  --height: auto;
}
</style>
