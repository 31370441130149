<template>
  <div class="flex flex-row">
    <user-icon class="flex-shrink-0" :user="user" disable-tooltip />
    <div class="flex flex-col justify-center ml-4">
      <div class="text-l font-bold" :class="{ 'text-primary': active }">
        {{ userDisplayName }}
      </div>
      <div
        class="text-sm text-secondary-500 font-semibold"
        :class="{ 'text-secondary-700': active }"
      >
        {{ userEmail }} {{ active }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import UserIcon from '@/components/molecules/UserIcon'
import { prettyName } from '@/utils/user'

export default defineComponent({
  name: 'UserSummary',
  components: {
    UserIcon,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    userDisplayName() {
      return prettyName(this.user)
    },
    userEmail() {
      return this.user?.email || ''
    },
  },
})
</script>
