<template>
  <section-item>
    <section-title>{{ $t('projects.layers3d') }}</section-title>
    <el-tree
      :data="tree.tree"
      @node-click="handleNodeClick"
      :props="defaultProps"
    >
      <template #default="{ node }">
        <annotation-item
          :node="node"
          :annotation="findAnnotation(node)"
          v-if="findAnnotation(node)"
          @edit-annotation="
            annotation => this.$emit('edit-annotation', annotation)
          "
          @change-annotation-visibility="
            args => this.$emit('change-annotation-visibility', args)
          "
          @annotation-deleted="args => this.$emit('annotation-deleted', args)"
        />
        <layer-item
          :node="node"
          :layer="findLayer(node)"
          v-else-if="findLayer(node)"
          @change-layer-visibility="
            args => this.$emit('change-layer-visibility', args)
          "
        />
        <parent-layer-item
          :node="node"
          :type="findTopLayerType(node)"
          v-else-if="findTopLayerType(node)"
          @change-layer-visibility="
            args => this.$emit('change-layer-visibility', args)
          "
        />
        <parent-layer-item
          :node="node"
          v-else-if="findTopOtherLayer(node)"
          :contains="isIncludedInOtherLayer"
          @change-layer-visibility="
            args => this.$emit('change-layer-visibility', args)
          "
        />
        <annotation-group-item
          :node="node"
          :group="findAnnotationGroup(node)"
          v-else-if="findAnnotationGroup(node)"
          @change-annotation-visibility="
            args => this.$emit('change-annotation-visibility', args)
          "
        />
        <root-annotation-item
          :node="node"
          v-else-if="findRootAnnotation(node)"
          @change-annotation-visibility="
            args => this.$emit('change-annotation-visibility', args)
          "
        />

        <tree-item :node="node" v-else />
      </template>
    </el-tree>
  </section-item>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { ElTree } from 'element-plus'
import { omit } from 'lodash'

import SectionTitle from '@/components/atoms/SectionTitle'
import SectionItem from '@/components/atoms/SectionItem'
import LayerItem from '@/components/organisms/model3d/LayerItem'
import AnnotationItem from '@/components/organisms/model3d/AnnotationItem'
import TreeItem from '@/components/molecules/TreeItem'
import ParentLayerItem from '@/components/organisms/model3d/ParentLayerItem'
import AnnotationGroupItem from '@/components/organisms/model3d/AnnotationGroupItem'
import RootAnnotationItem from '@/components/organisms/model3d/RootAnnotationItem'

import {
  annotationsToTree,
  layersToTree,
  createFirstChildItem,
  createTopLayer,
  createRootAnnotation,
  createTopOtherLayer,
} from '@/utils/tree'

export default defineComponent({
  components: {
    ElTree,
    SectionTitle,
    SectionItem,
    LayerItem,
    AnnotationItem,
    TreeItem,
    ParentLayerItem,
    AnnotationGroupItem,
    RootAnnotationItem,
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'label',
      },
    }
  },
  emits: [
    'edit-annotation',
    'change-annotation-visibility',
    'change-layer-visibility',
    'annotation-deleted',
  ],
  props: {
    project: {
      type: Object,
    },
  },
  computed: {
    annotations() {
      return this.getAnnotations(this.project?.id)
    },
    tree() {
      const annotationsTree = annotationsToTree(this.annotations)
      const layers = this.$store.getters.getLayersByProjectId(this.project?.id)
      const layersTree = layersToTree(layers)

      const rootAnnotation = createRootAnnotation(
        this.$t('annotations.label'),
        annotationsTree.tree
      )

      const pointCloudsTopLayer = createTopLayer(
        this.$t('projects.layers.pointClouds'),
        layersTree.byType.pointcloud,
        'pointcloud'
      )

      const daoTopLayer = createTopLayer(
        this.$t('projects.layers.dao'),
        layersTree.byType.dao,
        'dao'
      )

      const bimTopLayer = createTopLayer(
        this.$t('projects.layers.bim'),
        layersTree.byType.bim,
        'bim'
      )

      const sigTopLayer = createTopLayer(
        this.$t('projects.layers.sig'),
        layersTree.byType.sig,
        'sig'
      )

      const meshTopLayer = createTopLayer(
        this.$t('projects.layers.mesh'),
        layersTree.byType.mesh,
        'mesh'
      )

      const othersTopLayer = createTopOtherLayer(
        this.$t('projects.layers.others'),
        layersTree.byType.others
      )

      return {
        tree: [
          rootAnnotation,
          daoTopLayer,
          bimTopLayer,
          sigTopLayer,
          meshTopLayer,
          pointCloudsTopLayer,
          othersTopLayer,
          createFirstChildItem(this.$t('projects.layers.measures'), []),
          createFirstChildItem(this.$t('projects.layers.images'), []),
        ],
        byId: {
          ...annotationsTree.byId,
          ...layersTree.byId,
          [rootAnnotation.id]: omit(rootAnnotation, ['children']),
          [pointCloudsTopLayer.id]: omit(pointCloudsTopLayer, ['children']),
          [daoTopLayer.id]: omit(daoTopLayer, ['children']),
          [bimTopLayer.id]: omit(bimTopLayer, ['children']),
          [sigTopLayer.id]: omit(sigTopLayer, ['children']),
          [meshTopLayer.id]: omit(meshTopLayer, ['children']),
          [othersTopLayer.id]: omit(othersTopLayer, ['children']),
        },
      }
    },
    ...mapGetters(['getAnnotations']),
  },
  methods: {
    isIncludedInOtherLayer(layer) {
      return !['pointcloud', 'dao', 'bim', 'sig', 'mesh'].includes(layer.type)
    },
    findAnnotation(node) {
      return this.tree.byId[node?.data?.id]?.isAnnotation
        ? this.tree.byId[node?.data?.id].annotation
        : null
    },
    findTopLayerType(node) {
      return this.tree.byId[node?.data?.id]?.isTopLayer
        ? this.tree.byId[node?.data?.id]?.type
        : null
    },
    findTopOtherLayer(node) {
      return this.tree.byId[node?.data?.id]?.isTopOtherLayer
    },
    findRootAnnotation(node) {
      return this.tree.byId[node?.data?.id]?.isRootAnnotation
    },
    findAnnotationGroup(node) {
      return this.tree.byId[node?.data?.id]?.isAnnotationGroup
        ? this.tree.byId[node?.data?.id]?.group
        : null
    },
    findLayer(node) {
      return this.tree.byId[node?.data?.id]?.isLayer
        ? this.tree.byId[node?.data?.id].layer
        : null
    },
    handleNodeClick(node, treeNode) {
      const annotationClicked = this.findAnnotation(treeNode)

      if (annotationClicked) {
        this.openAnnotation(annotationClicked)
      }
    },
    ...mapActions(['openAnnotation']),
  },
})
</script>

<style>
@media (pointer: fine) {
  .rn-item-icon {
    opacity: 0;
  }

  .rn-item:hover .rn-item-icon {
    opacity: 1;
  }
}
</style>
