<template>
  <project-page>
    <div class="h-screen bg-secondary-300 relative">
      <card
        class="fixed z-20 left-2 top-2 right-2 md:right-auto md:max-w-96"
        :class="{
          'w-96 bottom-2': isSideBarOpened,
        }"
      >
        <div class="flex items-center">
          <icon-button
            class="hidden md:block"
            :icon="gridOutline"
            primary
            size="medium"
            @click="back"
            :title="$t('global.changeProject')"
          />
          <icon-button
            class="block md:hidden"
            :icon="arrowBackOutline"
            primary
            size="medium"
            @click="back"
            :title="$t('global.changeProject')"
          />

          <div class="pl-2 md:pr-10 flex-1 truncate">
            <project-label
              :project="project"
              :offline-status="projectOfflineStatus"
              layout="list"
            />
          </div>
          <icon-button
            :title="$t('global.saveWorkstate')"
            :icon="saveOutline"
            :disabled="isCurrentWorkStateIdenticalToMostRecent"
            primary
            size="medium"
            @click="save"
          />
          <icon-button
            :icon="
              isSideBarOpened ? closeCircleOutline : ellipsisVerticalOutline
            "
            primary
            size="medium"
            class="hidden md:block ml-3"
            :title="
              isSideBarOpened
                ? $t('global.lessDetails')
                : $t('global.moreDetails')
            "
            @click="isSideBarOpened = !isSideBarOpened"
          />
          <icon-button
            :icon="ellipsisVerticalOutline"
            primary
            size="medium"
            class="block md:hidden ml-3"
            @click="openProjectDetails"
          />
        </div>
        <project-details v-if="isSideBarOpened" hide-project-name />
      </card>
      <tool-bar
        :shifted="isSideBarOpened"
        :project="project"
        :is-options-menu-open="isOptionsMenuOpen"
        :is-measure-tools-menu-open="isMeasureToolsMenuOpen"
        @options-menu-open="openOptionsMenu"
        @create-annotation="$refs.annotationEditor?.startCreateAnnotation"
        @cancel-create-annotation="
          $refs.annotationEditor?.cancelCreateAnnotation
        "
        @measures-tool-menu-open="openMeasureToolsMenu"
      />
      <viewer
        ref="viewer"
        :project-id="this.projectId"
        @annotation-placed="annotationPlaced"
        @measure-tool-stopped="$refs?.measureToolsMenu?.deactivateTool"
      />
      <div class="fixed z-20 right-2 top-2 hidden md:flex flex-col items-end">
        <card class="leading-0">
          <user-menu />
        </card>
        <!--<personal-note @change-visibility="openPersonalNote" />-->
      </div>
      <card
        class="fixed z-20 left-4 md:left-auto md:right-6 leading-0"
        :class="{
          'bottom-56 md:bottom-44': isSlider4DVisible,
          'bottom-36 md:bottom-24': !isSlider4DVisible,
        }"
      >
        <icon-button
          :icon="locateOutline"
          primary
          size="medium"
          :title="$t('map3D.recenter')"
          tooltipPlacement="top"
          @click="$refs?.viewer?.recenterView"
        />
      </card>
      <!-- <card
        class="
          fixed
          z-20
          left-20
          bottom-20
          leading-0
          md:left-auto md:bottom-6 md:right-24
        "
      >
        <icon-button
          :icon="timeOutline"
          primary
          size="medium"
          :title="$t('map3D.openTimeline')"
          tooltipPlacement="top"
        />
      </card> -->
    </div>
    <options-menu
      :open="isOptionsMenuOpen"
      @close="isOptionsMenuOpen = false"
      :shifted="isSideBarOpened"
      @edit-annotation="startEditAnnotation"
      @change-layer-visibility="$refs.viewer?.setLayerVisibility"
      @change-annotation-visibility="$refs.viewer?.setAnnotationVisibility"
      :project="project"
      @annotation-deleted="$refs.viewer?.removeAnnotation"
    />
    <measure-tools-menu
      ref="measureToolsMenu"
      :open="isMeasureToolsMenuOpen"
      @close="isMeasureToolsMenuOpen = false"
      :shifted="isSideBarOpened"
      @start-measure-tool="$refs.viewer?.startMeasureTool"
    />
    <annotation-box
      :project="project"
      @edit-annotation="startEditAnnotation"
      :shifted="isPersonalNoteOpen"
      @change-annotation-visibility="$refs.viewer?.setAnnotationVisibility"
      @annotation-deleted="$refs.viewer?.removeAnnotation"
    />
    <annotation-editor
      :project="project"
      ref="annotationEditor"
      @place-annotation="placeAnnotation"
      :shifted="isSideBarOpened"
    />
  </project-page>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  arrowBackOutline,
  ellipsisVerticalOutline,
  saveOutline,
  locateOutline,
  timeOutline,
  closeOutline,
  closeCircleOutline,
  gridOutline,
} from 'ionicons/icons'

import { SaveWorkStateResult } from '@/store/work-state/work-state.utils'

import ProjectPage from '@/components/templates/ProjectPage'
import UserMenu from '@/components/organisms/UserMenu.vue'
import Card from '@/components/atoms/Card'
import ProjectLabel from '@/components/molecules/ProjectLabel'
import IconButton from '@/components/atoms/IconButton'
import pageTitle from '@/utils/pageTitle'
// import PersonalNote from '@/components/organisms/PersonalNote'
import Viewer from '@/components/organisms/Viewer'
import ToolBar from '@/components/organisms/ToolBar'
import { notify, notificationType } from '@/utils/notifications'
import ProjectDetails from '@/components/organisms/ProjectDetails'
import AnnotationEditor from '@/components/organisms/AnnotationEditor'
import OptionsMenu from '@/components/organisms/OptionsMenu'
import AnnotationBox from '@/components/organisms/AnnotationBox'
import MeasureToolsMenu from '@/components/organisms/MeasureToolsMenu'

import { back, findRoute } from '@/plugins/router'
import { hasSlider4D } from '@/utils/layer'

export default defineComponent({
  name: 'Map3D',
  components: {
    ProjectPage,
    UserMenu,
    Card,
    ProjectLabel,
    IconButton,
    Viewer,
    // PersonalNote,
    ToolBar,
    ProjectDetails,
    AnnotationEditor,
    OptionsMenu,
    AnnotationBox,
    MeasureToolsMenu,
  },
  setup() {
    return {
      arrowBackOutline,
      ellipsisVerticalOutline,
      saveOutline,
      locateOutline,
      timeOutline,
      closeOutline,
      closeCircleOutline,
      gridOutline,
    }
  },
  data() {
    return {
      isSideBarOpened: false,
      isOptionsMenuOpen: false,
      isMeasureToolsMenuOpen: false,
      isPersonalNoteOpen: false,
      currentWorkStatePolling: 0,
      currentWorkStateContinue: false,
    }
  },
  inject: ['projectId', 'project'],
  computed: {
    workStateList() {
      return this.projectId && this.workStateListByProjectId(this.projectId)
    },
    isCurrentWorkStateIdenticalToMostRecent() {
      return (
        this.currentWorkState != null &&
        this.currentWorkState === this.mostRecentWorkState
      )
    },
    selectedWorkStateId() {
      return this.workStateList?.selected?.id
    },
    currentWorkState() {
      return (
        this.workStateList?.currentDefinition ||
        this.workStateList?.selected?.definition
      )
    },
    mostRecentWorkState() {
      const list = this.workStateList
      if (list?.workStateIds?.length > 0) {
        const id = list.workStateIds[0]
        return list.byId[id]
      } else {
        return null
      }
    },
    projectOfflineStatus() {
      return this.projectId ? this.getProjectOfflineStatus(this.projectId) : ''
    },
    isSlider4DVisible() {
      if (this.projectId) {
        const layers = this.$store.getters.getLayersByProjectId(this.projectId)

        return layers.find(hasSlider4D)
      }

      return false
    },
    ...mapGetters(['workStateListByProjectId', 'getProjectOfflineStatus']),
  },
  methods: {
    annotationPlaced(annotation) {
      this.$refs?.annotationEditor?.annotationPlaced(annotation)
    },
    placeAnnotation(annotation) {
      this.$refs?.viewer?.placeAnnotation(annotation)
    },
    back() {
      console.log('this.project', this.project)
      const portal = this.$store.getters.portals.find(
        portal => portal.id === this.project?.portalId
      )

      back('projectListRetrocompatibility', { portalSlug: portal?.slug })
    },

    async save() {
      const ws = await this.viewerWorkStateToStore()
      if (!ws) return
      const { result, workStateId = '' } = await this.saveWorkState({
        projectId: this.projectId,
        definition: ws,
      })
      if (workStateId) {
        this.selectMostRecentWorkState({ projectId: this.projectId })
      }
      const prefix = this.notificationStringPrefixForWorkStateSaveResult(result)
      notify({
        type: this.notificationTypeForWorkStateSaveResult(result),
        title: this.$t(prefix + '.title', { code: workStateId }),
        message: this.$t(prefix + '.message', { code: workStateId }),
      })
    },
    openProjectDetails() {
      this.$router.push(findRoute('projectDetails', this.$route.params))
    },
    openOptionsMenu(open) {
      this.isOptionsMenuOpen = open
    },
    openMeasureToolsMenu(open) {
      this.isMeasureToolsMenuOpen = open
    },
    openPersonalNote(open) {
      this.isPersonalNoteOpen = open
    },
    startEditAnnotation(annotation) {
      this.$refs.annotationEditor?.startEditAnnotation(annotation)
      this.openOptionsMenu(false)
    },
    async viewerWorkStateToStore() {
      this.currentWorkStateContinue = true
      try {
        const workState = await this.$refs.viewer?.requestWorkState()
        if (workState && this.currentWorkStateContinue)
          this.setCurrentWorkState({ projectId: this.projectId, workState })
        return workState
      } catch {
        return null
      } finally {
        this.currentWorkStateContinue = false
      }
    },
    notificationTypeForWorkStateSaveResult(result) {
      switch (result) {
        case SaveWorkStateResult.success:
        case SaveWorkStateResult.pending:
          return notificationType.success
        case SaveWorkStateResult.errorSameAsLatest:
          return notificationType.warning
        default:
          return notificationType.error
      }
    },
    notificationStringPrefixForWorkStateSaveResult(result) {
      switch (result) {
        case SaveWorkStateResult.success:
          return 'workState.notifications.save'
        case SaveWorkStateResult.pending:
          return 'workState.notifications.pending'
        case SaveWorkStateResult.errorSameAsLatest:
          return 'workState.notifications.sameAsLatest'
        default:
          return 'workState.notifications.saveError'
      }
    },
    ...mapActions(['saveWorkState', 'selectMostRecentWorkState']),
    ...mapMutations(['setCurrentWorkState']),
  },
  watch: {
    selectedWorkStateId() {
      this.selectedWorkStateId = false
    },
  },
  mounted() {
    // this might cause issues due to vue-router optimization (reusable components)
    // but right now title is only used for desktop environment which doesn't change the page
    document.title = pageTitle(this.project?.name)
    this.viewerWorkStateToStore()
    this.currentWorkStatePolling = setInterval(
      () => this.viewerWorkStateToStore(),
      2500
    )
  },
  beforeUnmount() {
    clearInterval(this.currentWorkStatePolling)
  },
})
</script>
