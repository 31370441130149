let id = 0

const annotationToTreeItem = annotation => {
  id += 1

  return {
    label: annotation.title,
    id,
    isAnnotation: true,
  }
}

const annotationGroupToTreeItem = (group, children) => {
  id += 1

  return {
    label: group,
    id,
    isAnnotationGroup: true,
    group,
    children,
  }
}

const onlySameGroupAs = annotation => item =>
  item.label === annotation.group && item.children

const exceptSameGroupAs = annotation => item =>
  !onlySameGroupAs(annotation)(item)

export const annotationsToTree = annotations =>
  annotations.reduce(
    (acc, annotation) => {
      const itremTree = annotationToTreeItem(annotation)

      if (annotation.group) {
        const groupItem = annotationGroupToTreeItem(annotation.group, [
          ...acc.tree
            .filter(onlySameGroupAs(annotation))
            .flatMap(item => item.children),
          itremTree,
        ])

        const newAcc = {
          tree: [...acc.tree.filter(exceptSameGroupAs(annotation)), groupItem],
          byId: {
            ...acc.byId,
            [itremTree.id]: { annotation, isAnnotation: true },
            [groupItem.id]: {
              group: annotation.group,
              isAnnotationGroup: true,
            },
          },
        }

        return newAcc
      }
      return {
        tree: [...acc.tree, itremTree],
        byId: {
          ...acc.byId,
          [itremTree.id]: { annotation, isAnnotation: true },
        },
      }
    },
    { tree: [], byId: {} }
  )

const layerToTreeItem = layer => {
  id += 1

  return {
    label: layer.name,
    id,
    isLayer: true,
    type: layer.type,
  }
}

export const layersToTree = layers =>
  layers.reduce(
    (acc, layer) => {
      const itremTree = layerToTreeItem(layer)

      if (acc.byType[layer.type]) {
        acc.byType[layer.type].push(itremTree)
      } else {
        acc.byType.others.push(itremTree)
      }

      return {
        ...acc,
        byId: {
          ...acc.byId,
          [itremTree.id]: { layer, isLayer: true, type: layer.type },
        },
      }
    },
    {
      byType: {
        pointcloud: [],
        bim: [],
        dao: [],
        sig: [],
        mesh: [],
        others: [],
      },
      byId: {},
    }
  )

const isEmpty = children => !children || children.length === 0

const addZeroWhenEmpty = (label, children) =>
  !isEmpty(children) ? label : `${label} (0)`

export const createFirstChildItem = (label, children, more = {}) => ({
  label: addZeroWhenEmpty(label, children),
  children,
  disabled: isEmpty(children),
  ...more,
})

export const createTopLayer = (label, children, type) => {
  id += 1
  return createFirstChildItem(label, children, { id, isTopLayer: true, type })
}

export const createTopOtherLayer = (label, children) => {
  id += 1
  return createFirstChildItem(label, children, {
    id,
    isTopOtherLayer: true,
  })
}

export const createRootAnnotation = (label, children) => {
  id += 1
  return createFirstChildItem(label, children, { id, isRootAnnotation: true })
}
