<template>
  <layer-tool-button
    :class="className"
    :icon="trashOutline"
    @click="deleteAnnotation"
    :tooltip="$t('global.delete')"
  />
</template>

<script>
import { defineComponent } from 'vue'
import { trashOutline } from 'ionicons/icons'
import { mapClassName } from '@/utils/classNames'
import { mapActions, mapMutations } from 'vuex'
import { toastController } from '@ionic/vue'

import LayerToolButton from '@/components/molecules/LayerToolButton'

export default defineComponent({
  components: {
    LayerToolButton,
  },
  emits: ['click', 'change-annotation-visibility', 'annotation-deleted'],
  inject: ['projectId'],
  props: {
    class: {
      type: String,
      default: '',
    },
    annotation: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      trashOutline,
    }
  },
  computed: {
    ...mapClassName(),
  },
  methods: {
    async deleteAnnotation() {
      console.log('deleteAnnotation')
      const duration = 5000

      const annotationVisibility = this.$store.getters.isAnnotationVisible(
        this.projectId,
        this.annotation.id
      )

      this.$emit('change-annotation-visibility', {
        annotation: this.annotation,
        visibility: false,
      })

      this.prepareDeleteAnnotation({ annotationId: this.annotation?.id })

      const timeout = setTimeout(async () => {
        await this.removeAnnotation({ annotation: this.annotation })
        this.$emit('annotation-deleted', this.annotation)
      }, 5000)

      const toast = await toastController.create({
        header: this.$t('annotations.deleted'),
        position: 'top',
        duration,
        color: 'dark',
        buttons: [
          {
            text: this.$t('global.cancel'),
            role: 'cancel',
            handler: () => {
              this.cancelDeleteAnnotation({ annotationId: this.annotation?.id })

              this.$emit('change-annotation-visibility', {
                annotation: this.annotation,
                visibility: annotationVisibility,
              })
              clearTimeout(timeout)
            },
          },
        ],
        cssClass: 'rn-toast',
      })
      await toast.present()
    },
    ...mapActions(['closeAnnotation', 'removeAnnotation']),
    ...mapMutations(['prepareDeleteAnnotation', 'cancelDeleteAnnotation']),
  },
})
</script>

<style>
.rn-toast .toast-wrapper {
  --max-width: 500px;
}
</style>
