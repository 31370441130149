<template>
  <div class="flex-auto relative">
    <div class="absolute flex flex-row items-center rn-item pr-1 inset-0">
      <tooltip :title="node.label">
        <span
          class="pr-1 flex-auto text-xl md:text-base truncate"
          :class="{ 'opacity-50': node.disabled }"
          v-on:dblclick="doubleClick"
        >
          {{ node.label }}
        </span>
      </tooltip>
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Tooltip from '@/components/atoms/Tooltip'

export default defineComponent({
  components: {
    Tooltip,
  },
  props: {
    node: {
      type: Object,
    },
  },
  methods: {
    doubleClick() {
      console.log('double click')
    },
  },
})
</script>
