<template>
  <div class="flex flex-row items-top">
    <div class="self-start">{{ label }}</div>
    <div class="flex-grow w-8" />
    <Switch
      class="self-end"
      :model-value="value"
      @update:model-value="$emit('change', $event)"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Switch from '@/components/atoms/Switch'

export default defineComponent({
  components: { Switch },
  emits: ['change'],
  props: {
    label: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
})
</script>
