<template>
  <div class="leading-0">
    <textarea
      class="
        inline-block
        h-10
        w-full
        bg-secondary-400
        rounded
        px-2.5
        py-2
        outline-none
        text-secondary-700
        font-montserrat font-semibold
        resize-none
      "
      :class="classes"
      :type="type"
      v-model="value"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
import { reactive, computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'Textarea',
  props: {
    type: {
      type: String,
      default: () => 'text',
    },
    basic: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    class: {
      type: String,
      default: () => '',
    },
    size: {
      type: String,
      validator: function (value) {
        return ['small', 'medium', 'large'].indexOf(value) !== -1
      },
      default: 'medium',
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    props = reactive(props)
    return {
      classes: computed(() => ({
        'text-xl': props.size === 'large',
        'text-sm': props.size === 'medium',
        [props.class]: true,
      })),
      onClick() {
        emit('click')
      },
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
})
</script>
