import { ElNotification } from 'element-plus'

export const notify = ({ type, title, message }) => {
  ElNotification({
    type,
    title,
    message,
    position: 'top-left',
    offset: 80,
  })
}

export const notificationType = {
  success: 'success',
  error: 'error',
  warning: 'warning'
}
