<template>
  <project-permissions-editor
    class="overflow-y-auto inset-x-4"
    :permissions="userPermissionsForProject"
    @change="changePermission($event)"
  />
</template>

<script>
import { defineComponent } from 'vue'
import ProjectPermissionsEditor from '@/components/molecules/ProjectPermissionsEditor'

export default defineComponent({
  name: 'ProjectUserPermissionsEditor',
  components: { ProjectPermissionsEditor },
  emits: ['change'],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userPermissionsForProject() {
      return this.user.permissions
    },
  },
  methods: {
    changePermission({ name, value }) {
      if (
        typeof name === 'string' &&
        typeof value === 'boolean' &&
        this.user.permissions[name] !== value
      )
        this.$emit('change', { name, value })
    },
  },
})
</script>
