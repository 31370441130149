<template>
  <tree-item :node="node">
    <annotation-tool-button-edit
      class="rn-item-icon mr-1 hidden md:block"
      @click="editAnnotation(node)"
    />
    <annotation-tool-button-delete
      class="rn-item-icon mr-1 hidden md:block"
      :annotation="annotation"
      @annotation-deleted="args => $emit('annotation-deleted', args)"
      @change-annotation-visibility="
        args => $emit('change-annotation-visibility', args)
      "
    />
    <layer-tool-button-visibility
      class="mr-1"
      :class="{ 'rn-item-icon': isVisible }"
      @click="toggleVisibility"
      :isVisible="isVisible"
    />
  </tree-item>
</template>

<script>
import { defineComponent } from 'vue'

import LayerToolButtonVisibility from '@/components/organisms/LayerToolButtonVisibility'
import AnnotationToolButtonDelete from '@/components/organisms/AnnotationToolButtonDelete'
import AnnotationToolButtonEdit from '@/components/organisms/AnnotationToolButtonEdit'
import TreeItem from '@/components/molecules/TreeItem'

export default defineComponent({
  components: {
    LayerToolButtonVisibility,
    AnnotationToolButtonEdit,
    AnnotationToolButtonDelete,
    TreeItem,
  },
  emits: [
    'edit-annotation',
    'change-annotation-visibility',
    'annotation-deleted',
  ],
  inject: ['projectId'],
  props: {
    node: {
      type: Object,
    },
    annotation: {
      type: Object,
    },
  },
  computed: {
    isVisible() {
      return this.$store.getters.isAnnotationVisible(
        this.projectId,
        this.annotation?.id
      )
    },
  },
  methods: {
    toggleVisibility() {
      this.$emit('change-annotation-visibility', {
        annotation: this.annotation,
        visibility: !this.isVisible,
      })
    },
    editAnnotation() {
      this.openAnnotation(this.annotation)
      this.$emit('edit-annotation', this.annotation)
    },
  },
})
</script>

<style>
@media (pointer: fine) {
  .rn-item-icon {
    opacity: 0;
  }

  .rn-item:hover .rn-item-icon {
    opacity: 1;
  }
}
</style>
