<template>
  <div class="flex flex-row">
    <div
      :key="user.id"
      v-for="user in displayedMembers"
      class="w-7 cursor-pointer"
    >
      <user-icon :user="user" @click="$emit('user-clicked', user)" />
    </div>
    <div class="w-6" v-if="additionalMemberCount > 0">
      <div
        @click="$emit('user-clicked')"
        class="
          w-12
          h-12
          flex
          items-center
          justify-center
          rounded-full
          bg-secondary-100
          border-primary border-2
          text-primary
          font-montserrat font-bold
          text-large
          cursor-pointer
        "
      >
        +{{ additionalMemberCount }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import UserIcon from '@/components/molecules/UserIcon.vue'

export default defineComponent({
  components: {
    UserIcon,
  },
  emits: ['user-clicked'],
  props: {
    max: {
      type: Number,
      default: 6,
    },
    members: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    displayedMembers() {
      return this.members.slice(0, this.max)
    },
    additionalMemberCount() {
      return Math.max(0, this.members.length - this.max)
    },
  },
})
</script>
