<template>
  <div class="flex flex-row justify-between" v-if="workStateCount > 0">
    <Tooltip
      manual
      v-model="showTooltip"
      placement="top"
      :title="$t('projects.copiedInClipboard')"
    >
      <div
        class="
          font-montserrat font-semibold
          text-xl text-secondary-700
          select-all
          selection:bg-primary
          cursor-pointer
        "
        @click="loadWorkState(selectedWorkStateId)"
      >
        {{ selectedWorkStateId }}
      </div>
    </Tooltip>

    <icon-button
      :icon="copyOutline"
      :title="$t('projects.copyWorkStateCode')"
      @click="copyInClipboard"
      class="ml-auto"
      size="medium"
      primary
    />
    <icon-button
      :icon="chevronBackCircleOutline"
      :title="$t('projects.goBackPreviousWorkState')"
      :disabled="!previousWorkStateId"
      @click="loadWorkState(previousWorkStateId)"
      size="medium"
      primary
      class="ml-1"
    />
    <icon-button
      :icon="chevronForwardCircleOutline"
      :title="$t('projects.goForwardNextWorkState')"
      :disabled="!nextWorkStateId"
      @click="loadWorkState(nextWorkStateId)"
      size="medium"
      primary
      class="ml-1"
    />
  </div>
  <div v-else>
    {{ $t('projects.noWorkState') }}
  </div>
  <!-- TO DO V2.1
    <icon-button
      :icon="downloadOutline"
      size="medium"
      primary
      :title="$t('projects.importWorkState')"
    />
    -->
</template>

<script>
import { defineComponent } from 'vue'

import {
  copyOutline,
  chevronBackCircleOutline,
  chevronForwardCircleOutline,
  downloadOutline,
} from 'ionicons/icons'

import { mapActions, mapGetters } from 'vuex'
import IconButton from '@/components/atoms/IconButton'
import Tooltip from '@/components/atoms/Tooltip'
import { Clipboard } from '@capacitor/clipboard'
import { safeArray } from '@/utils/array'

export default defineComponent({
  components: {
    IconButton,
    Tooltip,
  },
  setup() {
    return {
      copyOutline,
      chevronBackCircleOutline,
      chevronForwardCircleOutline,
      downloadOutline,
    }
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showTooltip: false,
      workStatesPolling: null,
    }
  },
  computed: {
    workStateList() {
      return this.projectId
        ? this.workStateListByProjectId(this.projectId)
        : null
    },
    workStateCount() {
      return this.workStateList?.workStateIds?.length || 0
    },
    selectedWorkStateIndex() {
      if (!this.selectedWorkStateId) return -1
      const list = this.workStateList
      return list ? list.workStateIds.indexOf(this.selectedWorkStateId) : -1
    },
    selectedWorkStateId() {
      return this.workStateList?.selected?.id || ''
    },
    previousWorkStateId() {
      const ids = safeArray(this.workStateList?.workStateIds)
      const idx = this.selectedWorkStateIndex
      if (idx < 0) return ids.length > 0 ? ids[0] : null
      else if (idx < ids.length - 1) return ids[idx + 1]
      else return null
    },
    nextWorkStateId() {
      const idx = this.selectedWorkStateIndex
      return idx <= 0 ? null : this.workStateList.workStateIds[idx - 1]
    },
    currentWorkState() {
      return (
        this.workStateList?.currentDefinition ||
        this.workStateList?.selected?.definition
      )
    },
    selectedWorkState() {
      const id = this.workStateList?.selected?.id
      return id && this.workStateList.byId[id]
    },
    isCurrentWorkStateIdenticalToSelected() {
      return (
        this.currentWorkState == null ||
        this.currentWorkState === this.selectedWorkState
      )
    },
    ...mapGetters(['workStateListByProjectId']),
  },
  mounted() {
    this.updateWorkStates()
    this.workStatesPolling = setInterval(() => this.updateWorkStates(), 10000)
  },
  beforeUnmount() {
    clearInterval(this.workStatesPolling)
  },
  methods: {
    async updateWorkStates() {
      return await this.updateProjectWorkStateList({
        projectId: this.projectId,
        minCount: 50,
      })
    },
    async loadWorkState(workStateId) {
      if (workStateId)
        await this.selectWorkState({ projectId: this.projectId, workStateId })
    },
    async copyInClipboard() {
      await Clipboard.write({
        string: this.selectedWorkStateId,
      })
      this.showTooltip = true
      setTimeout(() => {
        this.showTooltip = false
      }, 5000)
    },
    ...mapActions(['updateProjectWorkStateList', 'selectWorkState']),
  },
})
</script>
