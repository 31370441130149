<template>
  <icon-button
    :class="`text-secondary-700
      md:text-secondary-500 md:hover:text-secondary-700 ${className}`"
    :icon="icon"
    @click="click"
    iconClass="text-3xl md:text-2xl"
    :title="tooltip"
    tooltipPlacement="top"
  />
</template>

<script>
import { defineComponent } from 'vue'
import { mapClassName } from '@/utils/classNames'
import IconButton from '@/components/atoms/IconButton'

export default defineComponent({
  components: {
    IconButton,
  },
  props: {
    class: {
      type: String,
      default: () => '',
    },
    icon: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    tooltip: {
      type: String,
      default: () => '',
    },
  },
  emits: ['click'],
  setup() {},
  methods: {
    click(event) {
      event.stopPropagation()
      event.preventDefault()
      this.$emit('click', event)
    },
  },
  computed: {
    ...mapClassName(),
  },
})
</script>
