<template>
  <div class="flex flex-row">
    <button
      v-for="(option, index) in options"
      :key="option.value"
      class="
        flex-1
        font-montserrat font-semibold
        py-1.5
        border-solid border-t border-b
      "
      :class="{
        'bg-primary border-primary text-secondary-100':
          value === option.value && !option.disabled,
        'bg-secondary-100 text-secondary-700 border-secondary-500':
          value !== option.value && !option.disabled,
        'bg-secondary-100 text-secondary-500 border-secondary-500':
          option.disabled,
        'cursor-pointer': !option.disabled,
        'rounded-l border-l': index === 0,
        'rounded-r': index === options.length - 1,
        'border-r': index !== valueIndex - 1,
      }"
      @click="click(option)"
    >
      {{ option.label }}
    </button>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: () => null,
    },
  },
  computed: {
    valueIndex() {
      return this.options.findIndex(option => option.value === this.value)
    },
  },
  methods: {
    click(option) {
      if (!option.disabled) {
        this.$emit('change', option.value)
      }
    },
  },
})
</script>
