<template>
  <el-progress
    :indeterminate="percentage < 0"
    :percentage="Math.abs(percentage)"
    :show-text="false"
  />
</template>

<script>
import { defineComponent } from 'vue'
import { ElProgress } from 'element-plus'

export default defineComponent({
  name: 'ProgressBar',
  components: {
    ElProgress,
  },
  props: {
    percentage: {
      type: Number,
      default: () => 0,
    },
  },
})
</script>
