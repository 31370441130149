<template>
  <div class="flex items-stretch">
    <Input
      :placeholder="$t('annotations.noFile')"
      type="file"
      accept="image/jpg"
      :class="className"
      size="medium"
      @change="uploadFile"
    />
    <!-- <Button
      class="flex-1"
      secondary
      :label="label"
      size="medium"
      @click="takePicture"
    /> -->
    <Button
      class="flex-1"
      secondary
      :label="label"
      size="medium"
      v-if="inProgress"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Camera, CameraResultType } from '@capacitor/camera'
import { mapActions } from 'vuex'
import { decode } from 'base64-arraybuffer'

// import { dataURItoBlob } from '@/utils/media'

import Button from '@/components/atoms/Button'
import Input from '@/components/atoms/Input'

export default defineComponent({
  name: 'ImageUploader',
  components: {
    Input,
    Button,
  },
  emits: ['change'],
  props: {
    mediaId: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      inProgress: false,
      file: '',
    }
  },
  methods: {
    async uploadFile(event) {
      console.log('uploadFile', event.target.files[0])

      this.inProgress = true

      const file = event.target.files[0]

      try {
        const media = await this.uploadImage(file)
        this.$emit('change', media?.id)
      } catch (error) {
        console.log('error', error)
      } finally {
        this.inProgress = false
      }
    },
    async takePicture() {
      // @see https://capacitorjs.com/docs/apis/camera
      const cameraPhoto = await Camera.getPhoto({
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Base64,
      })
      this.inProgress = true

      const blob = new Blob(
        [new Uint8Array(decode(cameraPhoto.base64String))],
        {
          type: `image/${cameraPhoto.format}`,
        }
      )

      // const imageBlob = dataURItoBlob(image.dataUrl)

      // https://stackoverflow.com/questions/62144590/convert-capacitor-camera-result-to-blob
      const file = new File([blob], `image.${cameraPhoto.format}`, {
        lastModified: new Date(),
        type: blob.type,
      })

      try {
        const media = await this.uploadImage(file)
        this.$emit('change', media?.id)
      } catch (error) {
        console.log('error', error)
      } finally {
        this.inProgress = false
      }
    },
    ...mapActions(['uploadImage']),
  },
  computed: {
    label() {
      if (this.inProgress) {
        return this.$t('global.uploadingPicture')
      }

      if (this.mediaId) {
        return this.$t('global.updatePicture')
      }

      return this.$t('global.importPicture')
    },
    className() {
      return {
        'flex-1': true,
        hidden: this.inProgress,
      }
    },
  },
})
</script>
