<template>
  <tooltip :title="tooltipTitle" :disabled="disableTooltip">
    <div class="w-12 h-12 grid col-auto row-auto">
      <div
        v-if="!avatarLoaded || avatarError"
        class="
          grid
          items-center
          justify-center
          col-start-1 col-end-1
          row-start-1 row-end-1
          rounded-full
          border-primary border-2
          bg-secondary-100
          text-primary
          font-bold
        "
      >
        <span>{{ initials }}</span>
      </div>
      <img
        v-if="!avatarError"
        :src="avatarUrl"
        :alt="initials"
        class="rounded-full col-start-1 col-end-1 row-start-1 row-end-1"
        @error="avatarError = true"
        @load="avatarLoaded = true"
      />
    </div>
  </tooltip>
</template>

<script>
import { defineComponent } from 'vue'
import Tooltip from '@/components/atoms/Tooltip.vue'
import { getUserAvatarUrl } from '@/utils/links.js'
import { initials, prettyName } from '@/utils/user.js'

export default defineComponent({
  name: 'UserIcon',
  components: {
    Tooltip,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    disableTooltip: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    tooltipTitle() {
      return this.user ? prettyName(this.user) : undefined
    },
    initials() {
      return initials(this.user)
    },
    avatarUrl() {
      const id = this.user?.id
      return id ? getUserAvatarUrl(id) : ''
    },
  },
  data: function () {
    return {
      avatarError: false,
      avatarLoaded: false,
    }
  },
  watch: {
    user() {
      this.avatarError = false
    },
  },
})
</script>
