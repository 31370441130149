<template>
  <div :id="viewerContainerId"></div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ViewerEvent, ViewerProxy } from '@/utils/viewer'
import { assign } from 'lodash'

export default {
  name: 'ModuleViewer',
  emits: ['annotation-placed'],
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    viewerContainerId: 'viewerContainer',
    viewerProxy: null,
    placingAnnotation: null,
    waitForViewerDataTimeoutID: 0,
  }),
  computed: {
    viewerState() {
      return this.$store.getters.viewerStateById(this.projectId)
    },
    workStateList() {
      return this.$store.getters.workStateListByProjectId(this.projectId)
    },
    selectedWorkStateId() {
      return this.workStateList?.selected?.id || ''
    },
    projectWorkStates() {
      return this.workStateList?.byId || {}
    },
    ...mapGetters(['workStateListByProjectId']),
  },
  watch: {
    projectId() {
      this.updateViewer()
    },
    async viewerState(newViewerState, oldViewerState) {
      if (newViewerState?.background !== oldViewerState?.background) {
        await this.viewerProxy?.setBackground(newViewerState.background)
      }
    },
    async selectedWorkStateId(id) {
      const ws = this.workStateList ? this.workStateList.byId[id] : null
      if (ws) await this.viewerProxy.sendWorkState(ws)
    },
  },
  async mounted() {
    if (this.viewerProxy) await this.viewerProxy.quit()
    this.viewerProxy = new ViewerProxy(
      window.rme,
      this.viewerContainerId,
      this.$store.getters.currentUser
    )
    this.viewerProxy.on(ViewerEvent.LOADED, async () => {
      console.log('this.viewerProxy.onViewerEvent.LOADED')
      const workSpaceId = await this.selectMostRecentWorkState({
        projectId: this.projectId,
      })
      const ws = workSpaceId ? this.projectWorkStates[workSpaceId] : null
      await this.viewerProxy.sendWorkState(ws)
    })
    this.viewerProxy.on(ViewerEvent.ANNOTATION_PLACED, viewerAnnotation => {
      if (!this.placingAnnotation) return
      const annotation = assign({}, this.placingAnnotation, viewerAnnotation)
      this.placingAnnotation = null
      this.$emit('annotation-placed', annotation)
    })
    this.viewerProxy.on(ViewerEvent.BACKGROUND_CHANGED, background => {
      this.$store.dispatch('updateViewerState', {
        projectId: this.projectId,
        background,
      })
    })
    this.viewerProxy.on('measure-tool-stopped', () => {
      this.$emit('measure-tool-stopped')
    })
    this.viewerProxy.on(
      ViewerEvent.ANNOTATION_ADDED,
      ({ viewerId, viewerAnnotation }) => {
        const annotation = this.$store.getters
          .getAnnotations(this.projectId)
          .find(a => a.id === viewerAnnotation.tag_id)

        if (annotation) {
          this.$store.commit('addViewerAnnotationBinding', {
            projectId: this.projectId,
            id: annotation.id,
            viewerId,
          })
        }
      }
    )
    this.viewerProxy.on(
      ViewerEvent.LAYER_ADDED,
      ({ viewerId, viewerLayer }) => {
        console.log('this.viewerProxy.onViewerEvent.LAYER_ADDED', viewerId)
        const layer = this.$store.getters
          .getLayersByProjectId(this.projectId)
          .find(l => l.url === viewerLayer.url)

        if (layer) {
          this.$store.commit('addViewerLayerBinding', {
            projectId: this.projectId,
            id: layer.id,
            viewerId,
          })
        }
      }
    )
    this.updateViewer()
  },
  async unmounted() {
    const proxy = this.viewerProxy
    this.viewerProxy = null
    if (proxy) await proxy.stop()
  },
  methods: {
    async requestWorkState() {
      return await this.viewerProxy?.requestWorkState()
    },
    async placeAnnotation(annotation) {
      this.placingAnnotation = annotation
      await this.viewerProxy?.addAnnotation(annotation)
    },
    async setLayerVisibility({ layer, visibility }) {
      console.log('toggleLayerVisibility', layer)
      const layerState = this.$store.getters.layerState(
        this.projectId,
        layer?.id
      )
      if (layerState) {
        try {
          await this.viewerProxy?.setVisibility(
            layerState?.viewerId,
            visibility
          )
          this.$store.commit('setLayerVisibility', {
            projectId: this.projectId,
            id: layer?.id,
            visibility,
          })
        } catch (error) {
          console.error('error', error)
        }
      } else {
        console.error('layerState not found', layer, layerState)
      }
    },
    async setAnnotationVisibility({ annotation, visibility }) {
      const annotationState = this.$store.getters.annotationState(
        this.projectId,
        annotation?.id
      )
      if (annotationState) {
        try {
          await this.viewerProxy?.setVisibility(
            annotationState?.viewerId,
            visibility
          )
          this.$store.commit('setAnnotationVisibility', {
            projectId: this.projectId,
            id: annotation?.id,
            visibility,
          })
        } catch (error) {
          console.error('error', error)
        }
      } else {
        console.error('annotation not found', annotation, annotationState)
      }
    },
    async removeAnnotation(annotation) {
      const annotationState = this.$store.getters.annotationState(
        this.projectId,
        annotation?.id
      )
      if (annotationState) {
        try {
          await this.viewerProxy?.removeObject(annotationState?.viewerId)
        } catch (error) {
          console.error('error', error)
        }
      } else {
        console.error('annotation not found', annotation, annotationState)
      }
    },
    async recenterView() {
      await this.viewerProxy?.recenterView()
    },
    async startMeasureTool(toolId) {
      await this.viewerProxy?.startMeasureTool(toolId)
    },
    async updateViewer() {
      if (!this.viewerProxy) return
      if (this.waitForViewerDataTimeoutID > 0)
        clearTimeout(this.waitForViewerDataTimeoutID)
      this.waitForViewerDataTimeoutID = 0
      const project = this.$store.getters.projectById(this.projectId)
      const layers = this.$store.getters.getLayersByProjectId(this.projectId)
      const annotations = this.$store.getters.getAnnotations(this.projectId)
      if (project && layers != null && annotations != null) {
        await this.viewerProxy.load({
          project,
          layers,
          annotations,
        })
        await this.viewerProxy.setBackground(this.viewerState?.background)
      } else {
        this.waitForViewerDataTimeoutID = setTimeout(
          () => this.updateViewer(),
          500
        )
      }
    },
    ...mapActions(['selectMostRecentWorkState']),
  },
}
</script>
