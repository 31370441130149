<template>
  <box
    :open="open"
    @close="$emit('close')"
    :slidable="slidable"
    :class="{
      'md:top-20 md:bottom-4 md:flex md:flex-col md:items-center md:justify-center': true,
      [className]: true,
      'md:left-20': !shifted,
      'md:left-110': shifted,
    }"
    contentClass="overflow-auto"
  >
    <section-title class="block md:hidden" v-if="title">
      {{ title }}
    </section-title>
    <slot />
  </box>
</template>

<script>
import { defineComponent } from 'vue'

import SectionTitle from '@/components/atoms/SectionTitle'
import Box from '@/components/molecules/Box'

export default defineComponent({
  components: {
    SectionTitle,
    Box,
  },
  emits: ['close'],
  props: {
    open: {
      type: Boolean,
      default: () => false,
    },
    slidable: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => '',
    },
    class: {
      type: String,
      default: () => '',
    },
    shifted: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    className() {
      return ''
    },
  },
})
</script>
