<template>
  <section-item>
    <section-title>{{ $t('projects.background') }}</section-title>
    <button-group
      :options="backgroundOptions"
      :value="background"
      @change="changeBackground"
    />
  </section-item>
</template>

<script>
import { defineComponent } from 'vue'
import SectionTitle from '@/components/atoms/SectionTitle'
import SectionItem from '@/components/atoms/SectionItem'
import ButtonGroup from '@/components/atoms/ButtonGroup'
import { backgroundOptions } from '@/utils/viewer'
import { translateOptions } from '@/utils/translate'

export default defineComponent({
  components: {
    SectionTitle,
    ButtonGroup,
    SectionItem,
  },
  props: {
    project: {
      type: Object,
    },
  },
  computed: {
    backgroundOptions() {
      return translateOptions(backgroundOptions(this.project), this.$t)
    },
    background() {
      return this.$store.getters.viewerStateById(this.project?.id)?.background
    },
  },
  methods: {
    changeBackground(background) {
      this.$store.dispatch('updateViewerState', {
        projectId: this.project?.id,
        background,
      })
    },
  },
})
</script>
