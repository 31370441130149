<template>
  <div>
    <boolean-value-input
      class="my-4"
      v-for="(name, index) in permissionNames"
      :key="index"
      :label="$t('projects.permissions.names.' + name)"
      :value="permissions[name]"
      @change="$emit('change', { name: name, value: $event })"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import BooleanValueInput from '@/components/atoms/BooleanValueInput.vue'

export default defineComponent({
  name: 'ProjectPermissionsEditor',
  components: { BooleanValueInput },
  emits: ['change'],
  props: {
    permissions: {
      type: Object,
      required: true,
    },
  },
  computed: {
    permissionNames: () => [
      'modelSee',
      'tagsSee',
      'toolsUse',
      'usersSee',
      'tagsAdd',
      'sharesSend',
      'customize',
      'tagsManage',
      'usersManage',
      'layersManage',
    ],
  },
})
</script>
