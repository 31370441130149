<template>
  <div>
    <div class="hidden lg:block">
      <div class="absolute inset-0 grid grid-cols-5 max-h-full">
        <user-list
          class="
            hidden
            lg:block
            col-span-2
            bg-gray-100
            text-secondary-700
            pt-8
            pl-4
          "
          :members="membersWithPermissions"
          :selected-ids="[selectedUser?.id]"
          @user-clicked="selectedUser = $event"
        />
        <div class="flex flex-col col-span-3">
          <div class="text-secondary-700 flex justify-end m-2">
            <icon-button
              :icon="closeIcon"
              @click="backToBefore()"
              size="medium"
            />
          </div>
          <project-user-permissions-editor
            class="m-2 text-sm mx-24 text-secondary-700"
            v-if="selectedUser"
            :user="selectedUser"
            @change="changePermission"
          />
          <div v-else class="items-center">
            <div
              class="
                text-center text-lg text-secondary-500
                font-montserrat font-medium
              "
            >
              {{ $t('projects.permissions.selectUser') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="block lg:hidden">
      <div v-if="selectedUser">
        <page-header @back="backToMembers()">
          <user-summary :user="selectedUser" />
        </page-header>
        <project-user-permissions-editor
          class="absolute top-32 bottom-0"
          :user="selectedUser"
          @change="changePermission"
        />
      </div>
      <div v-else>
        <page-header @back="backToBefore()">
          <div>Members</div>
        </page-header>
        <user-list
          class="absolute top-16 bottom-0 max-h-full"
          :members="membersWithPermissions"
          @user-clicked="selectedUser = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import UserList from '@/components/organisms/UserList'
import ProjectUserPermissionsEditor from '@/components/organisms/ProjectUserPermissionsEditor'
import UserSummary from '@/components/molecules/UserSummary'
import PageHeader from '@/components/molecules/PageHeader'
import { close } from 'ionicons/icons'
import IconButton from '@/components/atoms/IconButton.vue'

export default defineComponent({
  name: 'ProjectPermissions',
  components: {
    PageHeader,
    ProjectUserPermissionsEditor,
    UserList,
    UserSummary,
    IconButton,
  },
  emits: ['back'],
  props: {
    portalId: {
      // we need a way to retrieve the correct portalId value
      type: String,
      default: () => '0',
    },
    projectId: {
      type: String,
      required: true,
    },
    initialUserId: {
      type: String,
      default: null,
    },
    dismissSelf: {
      type: Function,
      default: null,
    },
  },
  computed: {
    projectPermissionsByUserId() {
      return this.projectPermissionsByProjectId(this.projectId)
    },
    members() {
      return this.projectMembersbyProjectId(this.projectId)
    },
    membersWithPermissions() {
      return this.members.map(user => {
        return {
          ...user,
          permissions: this.projectPermissionsByUserId[user.id],
        }
      })
    },
    memberCount() {
      return this.members.length
    },
    ...mapGetters([
      'projectMembersbyProjectId',
      'projectPermissionsByProjectId',
      'userById',
    ]),
  },
  data: function () {
    return {
      selectedUser: null,
    }
  },
  mounted() {
    if (this.initialUserId) {
      this.selectedUser = this.membersWithPermissions.find(
        m => m.id === this.initialUserId
      )
    }
  },
  setup() {
    return {
      closeIcon: close,
    }
  },
  methods: {
    backToMembers() {
      if (this.initialUserId == null) {
        this.selectedUser = null
      } else {
        this.backToBefore()
      }
    },
    backToBefore() {
      if (this.dismissSelf && typeof this.dismissSelf === 'function') {
        this.dismissSelf()
      } else {
        this.$emit('back')
      }
    },
    changePermission({ name, value }) {
      this.$store.dispatch('changeProjectUserPermissions', {
        portalId: this.portalId,
        projectId: this.projectId,
        userId: this.selectedUser?.id,
        name,
        value,
      })
    },
  },
})
</script>
