<template>
  <tool-bar-menu :open="open" @close="$emit('close')" :shifted="shifted">
    <section-item class="md:p-none">
      <section-title class="md:hidden">
        {{ $t('projects.measureTools') }}
      </section-title>
      <div
        class="
          flex flex-row
          md:flex-col
          flex-wrap
          space-x-2
          md:space-x-0
          space-y-0
          md:space-y-2
        "
      >
        <icon-button
          :icon="closeOutline"
          size="fat"
          primary
          @click="$emit('close')"
          class="hidden md:block"
          :title="$t('global.close')"
          tooltipPlacement="right"
        />
        <tooltip
          :title="$t(tool.label)"
          placement="right"
          v-for="tool in tools"
          :key="tool.id"
        >
          <button
            class="
              w-12
              h-12
              flex
              items-center
              justify-center
              rounded
              bg-primary
            "
            @click="click(tool)"
          >
            <img :src="tool.icon" class="w-8 h-8" />
          </button>
        </tooltip>
      </div>
    </section-item>
  </tool-bar-menu>
</template>

<script>
import { defineComponent } from 'vue'
import { closeOutline } from 'ionicons/icons'

import ToolBarMenu from '@/components/molecules/ToolBarMenu'
import IconButton from '@/components/atoms/IconButton'
import SectionTitle from '@/components/atoms/SectionTitle'
import SectionItem from '@/components/atoms/SectionItem'
import Tooltip from '@/components/atoms/Tooltip'

import azimut from '@/assets/svg/azimuth.svg'
import point from '@/assets/svg/point.svg'
import height from '@/assets/svg/height.svg'
import distance from '@/assets/svg/distance.svg'

const tools = [
  {
    label: 'measureTools.point',
    icon: point,
    id: 'Point',
  },
  {
    label: 'measureTools.distance',
    icon: distance,
    id: 'Distance',
  },
  {
    label: 'measureTools.height',
    icon: height,
    id: 'Height',
  },
  {
    label: 'measureTools.azimut',
    icon: azimut,
    id: 'Azimuth',
  },
]

export default defineComponent({
  components: {
    ToolBarMenu,
    IconButton,
    SectionTitle,
    SectionItem,
    Tooltip,
  },
  emits: ['start-measure-tool'],
  setup() {
    return {
      closeOutline,
      tools,
    }
  },
  props: {
    open: {
      type: Boolean,
      default: () => false,
    },
    shifted: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      activeToolId: null,
    }
  },
  methods: {
    click(tool) {
      console.log('start-measure-tool', tool.id)
      this.activeToolId = tool.id
      this.$emit('start-measure-tool', tool.id)
    },
    deactivateTool() {
      this.activeToolId = null
    },
  },
})
</script>
