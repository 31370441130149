<template>
  <div
    class="
      fixed
      rn-fullscreen-container
      inset-0
      flex
      items-center
      justify-center
      p-4
    "
    v-if="open"
  >
    <background-overlay
      :open="open"
      @close="$emit('close'), console.log('close')"
    />

    <card class="relative rn-fullscreen-modal">
      <slot />
    </card>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import BackgroundOverlay from '@/components/atoms/BackgroundOverlay'
import Card from '@/components/atoms/Card'

export default defineComponent({
  components: {
    BackgroundOverlay,
    Card,
  },
  props: {
    open: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['close'],
})
</script>

<style scoped>
.rn-fullscreen-container {
  z-index: 2000;
}

.rn-fullscreen-modal {
  z-index: 3000;
}
</style>
