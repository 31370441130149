<template>
  <section-list :class="className">
    <section-item>
      <div class="px-4 md:px-0">
        <div class="pb-3 pt-1 flex justify-center">
          <div
            class="
              rn-container-image
              rounded-md
              bg-secondary-500
              overflow-hidden
              relative
              flex-none
              w-44
              md:w-full
              h-44
            "
          >
            <div class="rn-image absolute inset-0 bg-center bg-cover">
              <img @error="onImageUrlError($event)" :src="imageUrl" />
            </div>
          </div>
        </div>
        <project-label
          :project="project"
          layout="title"
          hideIcons
          v-if="!hideProjectName"
        />
      </div>
    </section-item>
    <section-item>
      <div class="flex flex-row items-center">
        <div class="flex-1">
          <members-stack :members="members" :max="10" />
          <!-- TO DO V2.1 click disabled: @user-clicked="configureSharing({ user: $event })" -->
        </div>
        <!-- TO DO V2.1
          <icon-button
          :icon="shareSocialOutline"
          size="medium"
          primary
          :title="$t('projects.setupShare')"
          @click="configureSharing()"
        />
        -->
      </div>
    </section-item>
    <section-item>
      <div class="flex flex-row items-center">
        <div class="flex-1 text-secondary-700">
          <section-title>
            {{ $t('projects.location') }}
          </section-title>
          <div v-if="project?.latitude && project?.longitude">
            <span>
              <span class="text-xs">{{ $t('global.latitude') }}</span
              >:
              <span class="font-bold text-sm text-primary select-all">{{
                project?.latitude
              }}</span>
            </span>
            /
            <span>
              <span class="text-xs">{{ $t('global.longitude') }}</span
              >:
              <span class="font-bold text-sm text-primary select-all">{{
                project?.longitude
              }}</span>
            </span>
          </div>
          <div
            v-else
            class="text-xs text-secondary-700 font-montserrat font-medium"
          >
            {{ $t('projects.noLongitudeLatitude') }}
          </div>
        </div>
        <a
          :href="googleMapLink"
          class="leading-0"
          target="_blank"
          v-if="project?.latitude && project?.longitude"
        >
          <icon-button
            :icon="navigateOutline"
            size="medium"
            primary
            :title="$t('projects.openInGoogleMaps')"
          />
        </a>
      </div>
    </section-item>
    <section-item>
      <div class="flex flex-row items-center">
        <div class="flex-1 text-secondary-700">
          <section-title>
            {{ $t('projects.coordinateSystem') }}
          </section-title>
          <div
            v-if="
              project?.coordinateSystem &&
              project?.coordinateSystem.code &&
              project?.coordinateSystem.name
            "
          >
            <span
              >EPSG {{ project?.coordinateSystem?.code || '' }} |
              <span class="font-bold text-sm text-primary select-all">{{
                project?.coordinateSystem?.name || ''
              }}</span>
            </span>
          </div>
          <div
            v-else
            class="text-xs text-secondary-700 font-montserrat font-medium"
          >
            {{ $t('projects.noCoordinateSystem') }}
          </div>
        </div>
      </div>
    </section-item>
    <section-item v-if="shouldBeAbleToDownload">
      <section-title>
        {{ $t('projects.offline.mode') }}
      </section-title>
      <div class="flex flex-row items-center text-secondary-700">
        <div class="flex-1 font-montserrat font-medium text-xs pr-2">
          <div v-if="offlineStatus === OfflineItemStatus.yes">
            {{ $t('projects.offline.ready') }}
          </div>
          <div v-else-if="offlineStatus === OfflineItemStatus.partial">
            {{
              $t('projects.offline.partial', {
                availability: offlineDataAvailability,
              })
            }}
          </div>
          <div v-else-if="offlineStatus === OfflineItemStatus.no">
            {{ $t('projects.offline.none') }}
          </div>
          <div v-else-if="offlineStatus === OfflineItemStatus.failed">
            {{ $t('projects.offline.failed') }}
          </div>
          <div v-else class="pb-1.5">
            <div class="pb-1">{{ $t('projects.offline.inProgress') }}</div>
            <progress-bar :percentage="offlineDownloadProgress" />
          </div>
        </div>
        <Switch
          v-if="offlineDownloadProgress == null"
          :disabled="!offlineCanChangeRequest"
          v-model="offlineRequested"
        />
        <icon-button
          v-else
          :disabled="!offlineCanChangeRequest"
          :icon="closeCircleOutline"
          size="small"
          primary
          :title="$t('projects.offline.abort')"
          @click="abortDownload"
        />
      </div>
    </section-item>
    <section-item>
      <section-title>{{ $t('projects.workState') }}</section-title>
      <work-state-section :project-id="projectId" />
    </section-item>
    <section-item>
      <section-title>{{ $t('projects.keywords') }}</section-title>
      <div>
        <div
          class="
            inline-block
            py-0.25
            px-2
            bg-primary
            rounded
            text-secondary-100
            font-medium
            mr-1
            select-all
          "
          v-for="keyword in project?.keywords"
          :key="keyword"
        >
          {{ keyword }}
        </div>
        <div
          v-if="project?.keywords.length === 0"
          class="text-xs text-secondary-700 font-montserrat font-medium"
        >
          {{ $t('projects.noKeywords') }}
        </div>
      </div>
    </section-item>
    <!-- TO DO V2.1
      <section-item>
      <div class="flex flex-row justify-items-end">
        <div class="flex-1">
          <section-title>{{ $t('projects.export') }}</section-title>
        </div>
        <button
          class="text-secondary-700 hover:text-primary active:text-primary px-2"
        >
          <icon :icon="cameraOutline" size="medium" />
          <div class="text-sm font-montserrat font-medium">
            {{ $t('projects.screenshot') }}
          </div>
        </button>
        <button
          class="text-secondary-700 hover:text-primary active:text-primary px-2"
        >
          <icon :icon="archiveOutline" size="medium" />
          <div class="text-sm font-montserrat font-medium">
            {{ $t('projects.3DFiles') }}
          </div>
        </button>
      </div>
    </section-item>
    -->
    <!-- TO DO V2.1
    <section-item>
      <section-title>{{ $t('projects.activities') }}</section-title>
      <div class="flex flex-row overflow-x-auto">
        <div
          v-for="activity in activities"
          :key="activity"
          class="
            inline-block
            font-montserrat font-semibold
            text-secondary-700
            px-1
            text-center
            flex-16
          "
        >
          <div class="text-xs">{{ activity.label }}</div>
          <div class="text-xxs pt-1">{{ activity.date }}</div>
        </div>
      </div>
    </section-item>
    -->
  </section-list>
  <modal
    :open="isConfirmDownloadPopupOpen"
    @close="isConfirmDownloadPopupOpen = false"
  >
    <section-item>
      <section-title class="text-center">
        {{ $t('map3D.confirmDownloadPopup.download3dMap') }}
      </section-title>
      <div class="text-center pt-1">
        <icon :icon="warningOutline" class="text-4xl text-warning" />
      </div>
      <div class="font-montserrat text-sm text-warning font-medium">
        <p class="leading-5">
          {{ $t('map3D.confirmDownloadPopup.mapIsHeavy') }}
        </p>
        <p class="leading-5 pt-2">
          {{ $t('map3D.confirmDownloadPopup.confirmStart') }}
        </p>
      </div>
      <div class="pt-3">
        <Button
          secondary
          :label="$t('map3D.confirmDownloadPopup.cancel')"
          class="block w-full mt-4"
          @click="isConfirmDownloadPopupOpen = false"
          size="medium"
        />
        <Button
          primary
          :label="$t('map3D.confirmDownloadPopup.startDownload')"
          class="block w-full mt-3"
          @click="startDownload"
          size="large"
        />
      </div>
    </section-item>
  </modal>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

import {
  archiveOutline,
  cameraOutline,
  closeCircleOutline,
  navigateOutline,
  shareSocialOutline,
  warningOutline,
} from 'ionicons/icons'

import ProjectLabel from '@/components/molecules/ProjectLabel'
import IconButton from '@/components/atoms/IconButton'
import SectionList from '@/components/atoms/SectionList'
import SectionItem from '@/components/atoms/SectionItem'
import SectionTitle from '@/components/atoms/SectionTitle'
import Switch from '@/components/atoms/Switch'
import MembersStack from '@/components/organisms/MembersStack'
import {
  getGoogleMapLink,
  getProjectLargeCoverUrl,
  getProjectLargeCoverUrlError,
} from '@/utils/links'
import Modal from '@/components/molecules/Modal'
import Icon from '@/components/atoms/Icon'
import Button from '@/components/atoms/Button'
import ProgressBar from '@/components/atoms/ProgressBar'
import WorkStateSection from '@/components/organisms/WorkStateSection'

import { shouldBeAbleToDownload } from '@/utils/device'
import ProjectPermissions from '@/components/views/ProjectPermissions'
import { modalController } from '@ionic/vue'
import { OfflineItemStatus } from '@/store/offline/offline.utils'

export default defineComponent({
  components: {
    ProjectLabel,
    IconButton,
    SectionList,
    SectionItem,
    Switch,
    SectionTitle,
    MembersStack,
    Modal,
    Icon,
    Button,
    ProgressBar,
    WorkStateSection,
  },
  setup() {
    return {
      shareSocialOutline,
      navigateOutline,
      warningOutline,
      cameraOutline,
      archiveOutline,
      closeCircleOutline,
    }
  },
  props: {
    class: {
      type: String,
      default: () => '',
    },
    hideProjectName: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isConfirmDownloadPopupOpen: false,
      shouldBeAbleToDownload: true,
    }
  },
  inject: ['projectId', 'project'],
  computed: {
    className() {
      return this.class
    },
    imageUrl() {
      return this.project ? getProjectLargeCoverUrl(this.project) : ''
    },
    members() {
      return this.projectMembersbyProjectId(this.project?.id)
    },
    googleMapLink() {
      return this.project ? getGoogleMapLink(this.project) : ''
    },
    offlineInfo() {
      return this.getProjectOfflineInfo(this.project?.id)
    },
    offlineStatus() {
      return this.offlineInfo?.status || OfflineItemStatus.no
    },
    offlineDataAvailability() {
      const info = this.offlineInfo
      switch (info.status) {
        case OfflineItemStatus.yes:
          return 100
        case OfflineItemStatus.partial:
        case OfflineItemStatus.downloading:
          return info.total > 0
            ? Math.floor((1000 * info.current) / info.total) / 10
            : 0
        default:
          return 0
      }
    },
    offlineDownloadProgress() {
      const info = this.offlineInfo
      if (info.status === OfflineItemStatus.pending) {
        return -5
      } else if (info.status === OfflineItemStatus.downloading) {
        return info.total > 0 ? (100 * info.current) / info.total : -10
      } else {
        return null
      }
    },
    offlineCanChangeRequest() {
      return true
    },
    offlineRequested: {
      get() {
        return (
          this.offlineStatus !== OfflineItemStatus.no &&
          this.offlineStatus !== OfflineItemStatus.failed
        )
      },
      async set(offline) {
        if (!offline) {
          this.discardDownloadedData()
        } else {
          this.startDownload()
        }
      },
    },
    OfflineItemStatus: () => OfflineItemStatus,
    activities() {
      return this.getActivities(this.project?.id)
    },
    ...mapGetters([
      'projectMembersbyProjectId',
      'getProjectOfflineInfo',
      'getActivities',
    ]),
  },
  methods: {
    startDownload() {
      this.isConfirmDownloadPopupOpen = false
      this.startRetrievingProjectOfflineData({ projectId: this.project?.id })
    },
    abortDownload() {
      this.isConfirmDownloadPopupOpen = false
      if (this.offlineDownloadProgress)
        this.abortProjectOfflineDataRetrieval({ projectId: this.project?.id })
    },
    discardDownloadedData() {
      this.discardProjectOfflineData({ projectId: this.project?.id })
    },
    async configureSharing({ user = null } = {}) {
      const modal = await modalController.create({
        component: ProjectPermissions,
        cssClass: 'project-sharing-modal',
        componentProps: {
          projectId: this.projectId,
          initialUserId: user?.id,
          dismissSelf: () => modal.dismiss(),
        },
      })
      return modal.present()
    },
    onImageUrlError(evt) {
      const fallback = getProjectLargeCoverUrlError()
      if (fallback !== evt.target.src) evt.target.src = fallback
    },
    ...mapActions([
      'fetchProjectUsers',
      'startRetrievingProjectOfflineData',
      'discardProjectOfflineData',
      'abortProjectOfflineDataRetrieval',
    ]),
  },
  async mounted() {
    this.$nextTick(async function () {
      await this.fetchProjectUsers({ projectId: this.projectId })
    })

    this.shouldBeAbleToDownload = await shouldBeAbleToDownload()
  },
})
</script>

<style>
@media screen and (min-width: 768px) {
  .project-sharing-modal .modal-wrapper {
    border-radius: 12px;
  }
}

.project-sharing-modal .modal-wrapper {
  --width: 928px;
  --min-height: 600px;
}
</style>
