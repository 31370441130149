<template>
  <bottom-sheet :open="open" @close="$emit('close')">
    <slot />
  </bottom-sheet>
  <!-- TODO: include modal for desktop -->
</template>

<script>
import { defineComponent } from 'vue'

import BottomSheet from '@/components/atoms/BottomSheet'

export default defineComponent({
  name: 'Modal',
  components: {
    BottomSheet,
  },
  props: {
    open: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['close'],
})
</script>
