<template>
  <div class="h-10 flex items-strech justify-between">
    <button
      class="h-10 w-10 md:h-6 md:w-6 rounded border-2 border-solid"
      v-for="color in colors"
      :key="color"
      :style="{
        background: color,
        borderColor: color === value ? 'black' : 'transparent',
      }"
      @click="value = color"
    />
    <label
      class="
        h-10
        w-10
        md:h-6 md:w-6
        flex flex-row
        items-center
        justify-center
        relative
        rounded
        bg-secondary-400
        text-secondary-500 text-5xl
        hover:text-secondary-700
      "
      :for="id"
    >
      <input
        :id="id"
        type="color"
        class="block h-full outline-none border-0 p-0 appearance-none sr-only"
        :value="value"
        @blur="onBlur"
      />
      <div class="leading-0 pb-6 cursor-pointer">..</div>
    </label>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { generateId } from '@/utils/random'

export default defineComponent({
  props: {
    class: {
      type: String,
      default: '',
    },
    colors: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: String,
      default: () => '#63D82F',
    },
  },
  data() {
    return {
      id: generateId(),
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.changeValue(val)
      },
    },
  },
  methods: {
    onBlur(event) {
      this.changeValue(event.target.value)
    },
    changeValue(value) {
      this.$emit('update:modelValue', value.toUpperCase()) //vue v3 @see https://v3.vuejs.org/guide/migration/v-model.html
    },
  },
})
</script>
