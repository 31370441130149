<template>
  <tree-item :node="node">
    <layer-tool-button-visibility
      class="mr-1"
      :class="{ 'rn-item-icon': isVisible }"
      @click="toggleVisibility"
      :isVisible="isVisible"
      v-if="annotations.length > 0"
    />
  </tree-item>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

import LayerToolButtonVisibility from '@/components/organisms/LayerToolButtonVisibility'
import TreeItem from '@/components/molecules/TreeItem'

export default defineComponent({
  components: {
    LayerToolButtonVisibility,
    TreeItem,
  },
  emits: ['change-annotation-visibility'],
  props: {
    node: {
      type: Object,
    },
  },
  inject: ['projectId'],
  computed: {
    annotations() {
      return this.$store.getters.getAnnotations(this.projectId)
    },
    isVisible() {
      return this.annotations.some(annotation =>
        this.$store.getters.isAnnotationVisible(this.projectId, annotation?.id)
      )
    },
  },
  methods: {
    toggleVisibility() {
      this.annotations.forEach(annotation => {
        this.$emit('change-annotation-visibility', {
          annotation,
          visibility: !this.isVisible,
        })
      })
    },
    ...mapActions(['openAnnotation']),
  },
})
</script>

<style>
@media (pointer: fine) {
  .rn-item-icon {
    opacity: 0;
  }

  .rn-item:hover .rn-item-icon {
    opacity: 1;
  }
}
</style>
