<template>
  <responsive-renderer>
    <template v-slot:small>
      <bottom-sheet
        :open="open"
        @close="$emit('close')"
        :slidable="slidable"
        :class="className"
      >
        <slot />
      </bottom-sheet>
    </template>
    <template v-slot:large>
      <div class="fixed z-20 hidden md:block" :class="className" v-if="open">
        <Card :class="contentClass">
          <slot />
        </Card>
      </div>
    </template>
  </responsive-renderer>
</template>

<script>
/**
 * Box is a component displayed a card on desktop and a bottom sheet on mobile.
 * It is used to display content as an overlay on the map
 */

import { defineComponent } from 'vue'

import Card from '@/components/atoms/Card'
import BottomSheet from '@/components/atoms/BottomSheet'
import { mapClassName } from '@/utils/classNames'
import ResponsiveRenderer from '@/components/atoms/ResponsiveRenderer'

export default defineComponent({
  components: {
    Card,
    BottomSheet,
    ResponsiveRenderer,
  },
  emits: ['close'],
  props: {
    open: {
      type: Boolean,
      default: () => false,
    },
    slidable: {
      type: Boolean,
      default: () => false,
    },
    class: {
      type: String,
      default: () => '',
    },
    contentClass: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    ...mapClassName(),
  },
})
</script>
