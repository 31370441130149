<template>
  <div class="flex flex-col overflow-y-auto overflow-x-hidden inset-x-0">
    <div
      class="relative content-center py-4"
      :key="user.id"
      v-for="user in members"
    >
      <div class="relative">
        <user-summary
          class="cursor-pointer"
          :user="user"
          :active="selectedIds.includes(user.id)"
          @click="$emit('user-clicked', user)"
        />
        <active-indicator v-if="selectedIds.includes(user.id)" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import UserSummary from '@/components/molecules/UserSummary'
import ActiveIndicator from '@/components/atoms/ActiveIndicator'

export default defineComponent({
  components: { UserSummary, ActiveIndicator },
  emits: ['user-clicked'],
  props: {
    members: {
      type: Array,
      required: true,
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
  },
})
</script>
