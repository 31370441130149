<template>
  <icon-button
    :icon="icon"
    primary
    size="medium"
    :class="className"
    :active="active"
    :title="title"
    tooltipPlacement="right"
  >
    <active-indicator v-if="active" />
  </icon-button>
</template>

<script>
import { defineComponent } from 'vue'

import IconButton from '@/components/atoms/IconButton'
import ActiveIndicator from '@/components/atoms/ActiveIndicator'

export default defineComponent({
  components: {
    IconButton,
    ActiveIndicator,
  },
  props: {
    icon: {
      type: String,
      default: () => '',
    },
    active: {
      type: Boolean,
      default: () => false,
    },
    class: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    className() {
      return `px-6 py-1 md:px-4 md:py-2 md:my-4 ${this.class} relative`
    },
  },
})
</script>
