<template>
  <div
    class="
      rn-help
      fixed
      left-0
      right-0
      bottom-0
      h-24
      flex-col
      place-content-end
      content-center
      bg-gradient-to-b
      from-primary-transparent
      via-primary
      to-primary
      p-4
    "
    :class="{ flex: hintVisible, hidden: !hintVisible }"
  >
    <div class="flex flex-row items-center justify-center w-full">
      <div
        class="
          text-center
          font-semibold
          text-secondary-100
          font-montserrat
          text-lg
          flex
          items-center
          pr-2
        "
      >
        {{ $t('projects.longClick') }}
      </div>
      <icon-button
        :icon="closeOutline"
        size="fat"
        class="text-secondary-100"
        @click="close"
      />
    </div>
  </div>
  <tool-bar-menu :open="menuVisible" @close="close" :shifted="shifted">
    <div class="md:w-72">
      <section-item>
        <section-title>
          {{ isCreating ? $t('annotations.add') : $t('annotations.edit') }}
        </section-title>
        <form-item :label="$t('annotations.title')" required>
          <Input
            :placeholder="$t('annotations.title')"
            v-model="annotation.title"
          />
        </form-item>
        <form-item :label="$t('annotations.type')">
          <Select
            :placeholder="$t('annotations.type')"
            :options="types"
            v-model="annotation.type"
          />
        </form-item>
        <form-item
          :label="$t('annotations.description')"
          v-if="annotation.type === AnnotationTypes.TEXT"
        >
          <TextArea
            :placeholder="$t('annotations.description')"
            v-model="annotation.description"
          />
        </form-item>
        <form-item
          :label="$t('annotations.url')"
          v-if="
            annotation.type === AnnotationTypes.URL ||
            annotation.type === AnnotationTypes.REALTIME
          "
          required
        >
          <Input placeholder="https://example.com" v-model="annotation.url" />
        </form-item>
        <form-item
          :label="$t('annotations.photo')"
          v-if="annotation.type === AnnotationTypes.PHOTO"
          required
        >
          <image-uploader @change="imageAdded" :media-id="annotation.mediaId" />
        </form-item>
        <form-item :label="$t('annotations.folder')">
          <Input
            :placeholder="$t('annotations.folder')"
            v-model="annotation.group"
          />
        </form-item>
        <form-item :label="$t('annotations.color')">
          <color-picker v-model="annotation.color" :colors="annotationColors" />
        </form-item>
        <form-item>
          <Button
            :secondary="!isCreating"
            :primary="isCreating"
            :label="
              annotation.target?.length > 0
                ? $t('annotations.move')
                : $t('annotations.place')
            "
            class="block w-full"
            :icon="locateOutline"
            size="large"
            @click="placeAnnotation"
          />
        </form-item>
        <div class="pt-5" v-if="!isCreating">
          <Button
            primary
            size="large"
            :label="isCreating ? $t('annotations.add') : $t('annotations.edit')"
            class="block w-full"
            @click="save"
            :disabled="!canCreateAnnotation"
          />
        </div>
      </section-item>
    </div>
  </tool-bar-menu>
</template>

<script>
import { defineComponent } from 'vue'
import IconButton from '@/components/atoms/IconButton'
import { closeOutline, locateOutline } from 'ionicons/icons'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { cloneDeep } from 'lodash'

import { ViewerStatus } from '@/utils/viewer'
import SectionTitle from '@/components/atoms/SectionTitle'
import SectionItem from '@/components/atoms/SectionItem'
import FormItem from '@/components/atoms/FormItem'
import Button from '@/components/atoms/Button'
import Input from '@/components/atoms/Input'
import Select from '@/components/atoms/Select'
import ColorPicker from '@/components/atoms/ColorPicker'
import TextArea from '@/components/atoms/TextArea'
import ImageUploader from '@/components/molecules/ImageUploader'
import ToolBarMenu from '../molecules/ToolBarMenu'
import { isMediumSize } from '@/utils/responsive'

import {
  AnnotationTypes,
  annotationOptions,
  isValidAnnotation,
  createDefaultAnnotation,
} from '@/utils/annotations'
import { alertHttpError } from '@/utils/errors'
import { translateOptions } from '@/utils/translate'

export default defineComponent({
  components: {
    IconButton,
    SectionTitle,
    SectionItem,
    Button,
    FormItem,
    Input,
    ColorPicker,
    Select,
    TextArea,
    ImageUploader,
    ToolBarMenu,
  },
  setup() {
    return {
      closeOutline,
      AnnotationTypes,
      locateOutline,
    }
  },
  props: {
    project: {
      type: Object,
    },
    shifted: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['place-annotation'],
  data() {
    return {
      annotation: createDefaultAnnotation(),
      types: translateOptions(annotationOptions, this.$t),
    }
  },
  methods: {
    close() {
      this.setViewerStatus(ViewerStatus.DEFAULT)
    },
    async save() {
      try {
        if (!this.isCreating) {
          await this.updateAnnotation({ annotation: this.annotation })
        }
      } catch (error) {
        alertHttpError('saving annotation', error)
      }
    },
    async imageAdded(mediaId) {
      this.annotation.mediaId = mediaId
    },
    startCreateAnnotation() {
      this.annotation = createDefaultAnnotation({
        camera: null,
        target: null,
        placeholder: null,
        projectId: this.project?.id,
        color: this.annotationColors[0],
      })
      this.setViewerStatus(ViewerStatus.ANNOTATION_EDITION)
    },
    placeAnnotation() {
      this.setViewerStatus(ViewerStatus.ANNOTATION_PLACEMENT)
      this.$emit('place-annotation', this.annotation)
    },
    async annotationPlaced(annotation) {
      const { camera, target, placeholder } = annotation
      this.annotation.camera = camera
      this.annotation.target = target
      this.annotation.placeholder = placeholder
      this.annotation.projectId = this.project?.id
      this.setViewerStatus(ViewerStatus.ANNOTATION_EDITION)

      if (this.isCreating) {
        try {
          await this.$store.dispatch('createAnnotation', {
            annotation: this.annotation,
          })
          this.close()
        } catch (error) {
          alertHttpError('saving annotation', error)
        }
      }
    },
    cancelCreateAnnotation() {
      this.close()
    },
    startEditAnnotation(annotation) {
      this.annotation = cloneDeep(annotation)
      this.setViewerStatus(ViewerStatus.ANNOTATION_EDITION)
    },
    ...mapMutations(['setViewerStatus', 'setColors']),
    ...mapActions(['uploadImage', 'updateAnnotation']),
  },
  computed: {
    isCreating() {
      return this.annotation?.id == null
    },
    hintVisible() {
      return (
        this.viewerStatus === ViewerStatus.ANNOTATION_PLACEMENT &&
        !isMediumSize()
      )
    },
    menuVisible() {
      return (
        this.viewerStatus === ViewerStatus.ANNOTATION_EDITION ||
        (this.viewerStatus === ViewerStatus.ANNOTATION_PLACEMENT &&
          isMediumSize())
      )
    },
    canCreateAnnotation() {
      return isValidAnnotation(this.annotation)
    },
    ...mapGetters(['viewerStatus', 'annotationColors']),
  },
  watch: {
    'annotation.color'(newColor) {
      if (!this.annotationColors.includes(newColor)) {
        const only6FirstColors = [newColor, ...this.annotationColors].slice(
          0,
          6
        )
        this.setColors(only6FirstColors)
      }
    },
  },
})
</script>

<style scoped>
.rn-help {
  z-index: 500;
}
</style>
