const firstLetterCaps = function (input) {
  return input.length === 0
    ? ''
    : input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()
}

const capitalizedFirstChar = function (input) {
  return input.length === 0 ? '' : input.charAt(0).toUpperCase()
}

const firstWordCharCapitalized = function (input) {
  const transform = (input, separatorRegex, joinSeparator, f) => {
    return input.split(separatorRegex).map(f).join(joinSeparator)
  }
  return transform(input, '-', '-', i =>
    transform(i, /\s+/, ' ', firstLetterCaps)
  )
}

export const prettyName = user => {
  const first = firstWordCharCapitalized(user.firstName)
  const last = firstWordCharCapitalized(user.lastName)
  return first === last ? first : `${first} ${last}`
}

export const initials = user => {
  const first = capitalizedFirstChar(user.firstName)
  const last = capitalizedFirstChar(user.lastName)
  return `${first}${last}`
}
