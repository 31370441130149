import { notify } from '@/utils/notifications'

export const alertHttpError = (eventName, error) => {
  notify({
    type: 'error',
    title: `Error while ${eventName}`,
    message: JSON.stringify(error),
  })
  console.error('title', error)
}
