<template>
  <tool-bar-menu
    :open="open"
    @close="$emit('close')"
    slidable
    class="p-none"
    :shifted="shifted"
  >
    <div class="md:w-80 p-2 relative">
      <icon-button
        :icon="closeOutline"
        size="fat"
        primary
        @click="$emit('close')"
        class="hidden md:block absolute top-0 right-0"
        :title="$t('global.close')"
        tooltipPlacement="right"
      />
      <section-list>
        <layers-measures-and-annotations-section
          :project="project"
          @edit-annotation="annotation => $emit('edit-annotation', annotation)"
          @change-layer-visibility="
            args => $emit('change-layer-visibility', args)
          "
          @change-annotation-visibility="
            args => $emit('change-annotation-visibility', args)
          "
          @annotation-deleted="args => $emit('annotation-deleted', args)"
        />
        <background-section :project="project" />
        <!--<section-item>
          <section-title>{{ $t('projects.imagery') }}</section-title>
          <div class="flex flex-row flex-wrap space-x-2">
            <button
              class="w-12 h-12 bg-primary rounded"
              v-for="imagery in imageries"
              :key="imagery.key"
            />
          </div>
        </section-item>
        <section-item>
          <section-title>{{ $t('projects.ground') }}</section-title>
          <div class="flex flex-row flex-wrap space-x-2">
            <button
              class="w-12 h-12 bg-primary rounded"
              v-for="ground in grounds"
              :key="ground.key"
            />
          </div>
        </section-item>
        <section-item>
          <section-title>{{ $t('projects.optimization') }}</section-title>
          <div class="flex flex-row align-top">
            <div class="flex-1 text-secondary-700">
              {{
                automaticOptimization
                  ? $t('projects.optimizations.automatic')
                  : $t('projects.optimizations.customized')
              }}
            </div>
            <Switch v-model="automaticOptimization" />
          </div>
        </section-item>
        <section-item>
          <section-title>{{ $t('projects.filter') }}</section-title>
          <div class="flex flex-row align-top">
            <div class="flex-1 text-secondary-700">
              {{
                enabledFilter
                  ? $t('projects.filters.enabled')
                  : $t('projects.filters.disabled')
              }}
            </div>
            <Switch v-model="enabledFilter" />
          </div>
        </section-item>-->
      </section-list>
    </div>
  </tool-bar-menu>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { closeOutline, eyeOutline } from 'ionicons/icons'
import ToolBarMenu from '@/components/molecules/ToolBarMenu'
import IconButton from '@/components/atoms/IconButton'
// import SectionTitle from '@/components/atoms/SectionTitle'
// import SectionItem from '@/components/atoms/SectionItem'
import SectionList from '@/components/atoms/SectionList'
// import Switch from '@/components/atoms/Switch'
import LayersMeasuresAndAnnotationsSection from '@/components/organisms/model3d/LayersMeasuresAndAnnotationsSection'
import BackgroundSection from '@/components/organisms/model3d/BackgroundSection'

export default defineComponent({
  components: {
    ToolBarMenu,
    IconButton,
    // SectionTitle,
    // SectionItem,
    SectionList,
    // Switch,
    LayersMeasuresAndAnnotationsSection,
    BackgroundSection,
  },
  setup() {
    return {
      closeOutline,
      eyeOutline,
    }
  },
  props: {
    open: {
      type: Boolean,
      default: () => false,
    },
    project: {
      type: Object,
    },
    shifted: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: [
    'edit-annotation',
    'change-layer-visibility',
    'change-annotation-visibility',
    'annotation-deleted',
  ],
  data() {
    return {
      imageries: [
        { value: '0' },
        { value: '1' },
        { value: '2' },
        { value: '3' },
        { value: '4' },
        { value: '5' },
      ],
      grounds: [{ value: '0' }, { value: '1' }, { value: '2' }],
      automaticOptimization: true,
      enabledFilter: false,
    }
  },
  computed: {
    annotations() {
      return this.getAnnotations(this.project?.id)
    },
    ...mapGetters(['getAnnotations']),
  },
  methods: {
    ...mapActions(['openAnnotation']),
    handleNodeClick(node) {
      const annotationClicked = this.annotations.find(a => a.id === node.id)

      if (annotationClicked) {
        this.openAnnotation({
          projectId: this.project?.id,
          annotationId: annotationClicked.id,
        })
      }
    },
    customNodeClass() {
      return ''
    },
    toggleVisibility(event) {
      event.preventDefault()
      event.stopPropagation()
    },
    editAnnotation(node) {
      const annotationClicked = this.annotations.find(a => a.id === node.id)

      if (annotationClicked) {
        this.$emit('edit-annotation', annotationClicked)
      }
    },
    ...mapActions(['openAnnotation']),
  },
})
</script>

<style>
@media (pointer: fine) {
  .rn-item-icon {
    opacity: 0;
  }

  .rn-item:hover .rn-item-icon {
    opacity: 1;
  }
}
</style>
