<template>
  <el-switch v-model="value" :width="50" :disabled="disabled" />
</template>

<script>
import { defineComponent } from 'vue'
import { ElSwitch } from 'element-plus'

export default defineComponent({
  name: 'Switch',
  components: {
    ElSwitch,
  },
  props: {
    class: {
      type: String,
      default: () => '',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
    className: function () {
      return this.class
    },
  },
})
</script>
