<template>
  <div class="rounded bg-secondary-100 p-2 shadow-card" :class="className">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Card',
  props: {
    class: {
      type: String,
      default: '',
    },
  },
  computed: {
    className: function () {
      return this.class
    },
  },
})
</script>
